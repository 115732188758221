import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "@material-ui/lab";
import { Star } from "@material-ui/icons";

import ReviewCard from "./ReviewCard";
import { getCustomerReviews } from "../../store/actions/orderActions";

const CustomerReviews = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { customerReviews = [] } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getCustomerReviews(token));
  }, [dispatch, token]);

  const averageRating =
    customerReviews.reduce(
      (total, item) => total + item?.overallExperience?.rating,
      0
    ) / customerReviews?.length || 0;

  const totalReviews = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(customerReviews?.length);

  const ratingsCount = [
    {
      bgColor: "#35b99e",
      lightBgColor: "#ddfff8",
      total: customerReviews.filter(
        (item) => item?.overallExperience?.rating == 5
      ).length,
    },
    {
      bgColor: "#dc80ff",
      lightBgColor: "#f7e1ff",
      total: customerReviews.filter(
        (item) => item?.overallExperience?.rating == 4
      ).length,
    },
    {
      bgColor: "#f5bf41",
      lightBgColor: "#fff3d8",
      total: customerReviews.filter(
        (item) => item?.overallExperience?.rating == 3
      ).length,
    },
    {
      bgColor: "#34c1ef",
      lightBgColor: "#d8f6ff",
      total: customerReviews.filter(
        (item) => item?.overallExperience?.rating == 2
      ).length,
    },
    {
      bgColor: "#fd8024",
      lightBgColor: "#ffefe4",
      total: customerReviews.filter(
        (item) => item?.overallExperience?.rating == 1
      ).length,
    },
  ];

  const highestRating = Math.max(...ratingsCount.map(x => x.total));

  return (
    <div className="container mt-4 mb-3">
      <h2 className="fw-semibold">Reviews</h2>
      <div className="row mt-4">
        <div className="col-md-4 mb-3 text-center">
          <h5 className="fw-semibold">Total Reviews</h5>
          <span className="display-4">{totalReviews}</span>
          <p>Growth in reviews this year</p>
        </div>
        <div className="col-md-4 mb-3 border-start border-end text-center">
          <h5 className="fw-semibold">Average Rating</h5>
          <div className="d-flex flex-column align-items-center">
            <span className="display-4">{averageRating.toFixed(1)}</span>
            <Rating disabled value={averageRating} size="medium" />
          </div>
          <p>Average rating this year</p>
        </div>
        <div className="col-md-4 mb-3">
          {[5, 4, 3, 2, 1].map((rate, index) => (
            <RatingBar
              key={rate}
              rate={rate}
              ratingCount={ratingsCount[index]?.total}
              highestRating={highestRating}
              {...ratingsCount[index]}
            />
          ))}
        </div>
      </div>
      <hr className="my-4" />
      <div className="pb-4">
        {customerReviews.map((item, i) => (
          <React.Fragment key={i}>
            <ReviewCard item={item} />
            {customerReviews.length > 1 && <hr className="my-3" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;

const RatingBar = ({
  rate,
  ratingCount,
  highestRating,
  bgColor,
  lightBgColor,
}) => {
  const progressWidth = (ratingCount / highestRating) * 100 || 2;
  return (
    <div className="d-flex gap-4 align-items-center mb-2">
      <Star className="text-secondary" fontSize="small" />
      <span className="ms-2 me-2">{rate}</span>
      <div
        className="progress mb-0 w-100"
        style={{ backgroundColor: lightBgColor, height: "5px" }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{ backgroundColor: bgColor, width: `${progressWidth}%` }}
        ></div>
      </div>
      <span className="ms-2">
        {new Intl.NumberFormat("en-US", {
          notation: "compact",
          compactDisplay: "short",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(ratingCount)}
      </span>
    </div>
  );
};
