import React, { useState } from "react";

const Expandable = ({ text, lines = 3, danger }) => {
  const [seeMore, setSeeMore] = useState(false);

  return danger ? (
    <span
      style={{
        whiteSpace: "pre-line",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: seeMore ? "unset" : lines,
        overflow: "hidden",
      }}
      onClick={() => setSeeMore(!seeMore)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <span
      style={{
        whiteSpace: "pre-line",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: seeMore ? "unset" : lines,
        overflow: "hidden",
      }}
      onClick={() => setSeeMore(!seeMore)}
    >
      {text}
    </span>
  );
};

export default Expandable;
