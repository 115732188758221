import React from "react";
import { useHistory } from "react-router";

import Expandable from "../../components/UI/Expandable";
import { formatDate } from "../../utilities";

const VendorCard = ({ review }) => {
  const history = useHistory();

  const handleClickActivity = (id) => {
    history.push(`/dashboard/orders/activity/${id}`, id);
  };

  return (
    <tr>
      <td
        className="text-blue cursor-pointer"
        onClick={() => handleClickActivity(review?.orderItemId)}
      >
        #{review?.orderItem?.orderNo}
      </td>
      <td>
        {review.vendorInfo?.firstName} {review.vendorInfo?.lastName}
      </td>
      <td
        style={{
          minWidth: 330,
          maxWidth: 400,
        }}
      >
        <Expandable text={review.vendorQuestionnaire?.suggestions?.value} />
      </td>
      <td>{formatDate(review.createdAt)}</td>
    </tr>
  );
};

export default VendorCard;
