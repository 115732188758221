/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { FaChartLine } from "react-icons/fa";
import { Card, CardHeader, Button } from "reactstrap";
import { BiDollar, BiMoney, BiCube, BiCart } from "react-icons/bi";

import ManageVendor from "./manageVendor/ManageVendor";
import * as userActions from "../../store/actions/userActions";
import * as orderActions from "../../store/actions/orderActions";
import "./Vendor.css";
import "./SingleVendor.css";
import Swal from "sweetalert2";
import StnImage from "../../components/UI/Image";
import { profilePlaceholder } from "../../utilities";

const SingleStoreVendor = (props) => {
  const {
    auth,
    vendorData,
    getCurrentVendor,
    updateVendor,
    vendorOrders,
    vendorOrdersLoading,
    getVendorOrders,
    match,
  } = props;
  const dispatch = useDispatch();
  const vendorInfo = vendorData?.vendor ?? {};

  useEffect(() => {
    auth?.token && getCurrentVendor(match.params.id, auth?.token);
  }, [auth?.token]);

  const disableAccount = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to ${
        vendorInfo?.activate ? "disable" : "activate"
      } this account`,
      confirmButtonText: "Yes, I confirm",
      showCancelButton: true,
      cancelButtonColor: "gray",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#007BFF",
    });
    if (isConfirmed && vendorData?.vendor) {
      dispatch(
        userActions.activateUser({
          id: vendorData?.vendor?._id,
          token: auth?.token,
          option: "vendor",
        })
      );
    }
  };

  return (
    <>
      <Card className="singleVendorTop">
        <CardHeader className="border-0">
          <StnImage
            src={vendorInfo?.profilePic}
            placeholder={profilePlaceholder}
            width={45}
            height={45}
            alt={vendorInfo?.firstName}
            className="user-profile"
          />
          <span className="singleVendorText">
            <i>
              {vendorInfo?.storeName} - {vendorInfo?.firstName}{" "}
              {vendorInfo?.lastName}
            </i>
          </span>
          {/* <span className="closeEnvelope">
            <BiEnvelope />
          </span> */}

          {/* <span className="vendorAddNewBtns">
            <Button color="primary" href="#pablo" size="sm">
              <BiUserPlus className="usrIcon" /> Add New
            </Button>

            <Button color="primary" href="#pablo" size="sm">
              <BiUser className="usrIcon" />
            </Button>
          </span> */}
        </CardHeader>
      </Card>

      <div className="sales_cards row">
        <div className="col-sm-6 col-lg-3">
          <Card className="salesCards">
            <div className="row">
              <div className="col-md-3 pinkBack">
                <BiDollar className="moneyIcon" />
              </div>

              <div className="col-md-9 second_half_text">
                <div className="sales_money">${vendorData?.grossSale}</div>
                <div className="small-text">Gross sales in this month</div>
              </div>
            </div>
          </Card>
        </div>

        <div className="col-sm-6 col-lg-3">
          <Card className="salesCards">
            <div className="row">
              <div className="col-md-3 blueBack">
                <BiMoney className="moneyIcon" />
              </div>

              <div className="col-md-9 second_half_text">
                <div className="sales_money">${vendorData?.grossSale}</div>
                <div className="small-text">Earnings in this month</div>
              </div>
            </div>
          </Card>
        </div>

        <div className="col-sm-6 col-lg-3">
          <Card className="salesCards">
            <div className="row">
              <div className="col-md-3 yellowBack">
                <BiCube className="moneyIcon" />
              </div>

              <div className="col-md-9 second_half_text">
                <div className="sales_money">
                  {vendorData?.totalProductsOfVendor} Products
                </div>
                <div className="small-text">Total products posted</div>
              </div>
            </div>
          </Card>
        </div>

        <div className="col-sm-6 col-lg-3">
          <Card className="salesCards">
            <div className="row">
              <div className="col-md-3 seaGreenBack">
                <BiCart className="moneyIcon" />
              </div>
              <div className="col-md-9 second_half_text">
                <div className="sales_money">
                  {vendorData?.ItemsSoldInLastMonth} Items
                </div>
                <div className="small-text">Sold in this month</div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="single_vendor_details">
        <Card className="userDetailCards">
          <table>
            <tbody>
              <tr className="mt-15">
                <td>Store</td>
                <td className="orangeText">{vendorInfo?.storeName ?? "-"}</td>
              </tr>
              <tr>
                <td>Store Admin</td>
                <td>
                  {vendorInfo?.firstName} {vendorInfo?.lastName}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{vendorInfo?.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>Address</td>
                <td className="greenText">{vendorInfo?.storeAddress}</td>
              </tr>
            </tbody>
          </table>

          <div className="vendorButtons mt-3">
            <Button color="primary" href="#pablo" size="sm">
              <FaChartLine /> SALES REPORT
            </Button>
            <Button color="primary" onClick={disableAccount} size="sm">
              {vendorInfo?.activate ? "DISABLE" : "ACTIVATE"} ACCOUNT
            </Button>
          </div>
        </Card>

        <div className="collapible-section mt-5">
          <ManageVendor
            auth={auth}
            vendorInfo={vendorInfo}
            vendorData={vendorData}
            updateVendor={updateVendor}
            vendorOrders={vendorOrders}
            vendorOrdersLoading={vendorOrdersLoading}
            getVendorOrders={getVendorOrders}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vendorData: state.user.vendor,
    vendorOrders: state.order.vendorOrders,
    vendorOrdersLoading: state.order.vendorOrdersLoading,
    errorMsg: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentVendor: (id, token) =>
      dispatch(userActions.getCurrentVendor(id, token)),
    updateVendor: (id, payload, token) =>
      dispatch(userActions.updateVendor(id, payload, token)),
    getVendorOrders: (id, token) =>
      dispatch(orderActions.getVendorOrders(id, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleStoreVendor);
