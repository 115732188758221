/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Logout from "./views/Auth/Logout";

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-tv-2 text-primary",
    layout: "/dashboard",
    access: "home",
  },
  {
    path: "/medias",
    name: "Media",
    icon: "ni ni-collection text-blue",
    layout: "/dashboard",
    access: "media",
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "ni ni-collection text-blue",
    layout: "/dashboard",
    access: "articles",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-app text-red",
    layout: "/dashboard",
    access: "products",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-ungroup text-orage",
    layout: "/dashboard",
    access: "categories",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-cart text-blue",
    layout: "/dashboard",
    access: "orders",
  },
  {
    path: "/withdraw",
    name: "Withdrawal",
    icon: "ni ni-credit-card text-orange",
    layout: "/dashboard",
    access: "withdrawal",
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "ni ni-spaceship text-red",
    layout: "/dashboard",
    access: "coupons",
  },
  {
    path: "/vendorstore",
    name: "Store Vendor",
    icon: "ni ni-single-02 text-red",
    layout: "/dashboard",
    access: "storevendor",
  },
  {
    path: "/users",
    name: "Customers",
    icon: "ni ni-circle-08 text-blue",
    layout: "/dashboard",
    access: "customers",
  },
  {
    path: "/users-with-roles",
    name: "Users With Roles",
    icon: "ni ni-circle-08 text-blue",
    layout: "/dashboard",
    access: "userswithrole",
  },
  {
    path: "/refund",
    name: "Refund",
    icon: "ni ni-send text-blue",
    layout: "/dashboard",
    access: "refund",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "fas fa-bell text-blue",
    layout: "/dashboard",
    access: "notifications",
  },
  {
    path: "/trash",
    name: "Trash",
    icon: "fas fa-trash text-blue",
    layout: "/dashboard",
    access: "trash",
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: "fas fa-comments text-blue",
    layout: "/dashboard",
    access: "feedback",
  },
  {
    path: "/footerMenu",
    name: "Footer Menu",
    icon: "fas fa-bars text-blue",
    layout: "/dashboard",
    access: "footerMenu",
  },
  {
    path: "/setting",
    name: "Setting",
    icon: "ni ni-settings-gear-65 text-blue",
    layout: "/dashboard",
    access: "setting",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-chart-bar-32 text-orange",
    layout: "/dashboard",
    access: "reports",
  },
  {
    path: "/customer-review",
    name: "Customer Review",
    icon: "ni ni-bullet-list-67 text-blue",
    layout: "/dashboard",
    access: "customerReview",
  },
  {
    path: "/review",
    name: "Vendor Review",
    icon: "ni ni-bullet-list-67 text-blue",
    layout: "/dashboard",
    access: "review",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-user-run",
    component: Logout,
    layout: "/auth",
  },
];

export default routes;

export const notificationRoutes = [
  {
    path: "article",
    name: "Article",
    layout: "/dashboard",
    access: "articles",
  },
  {
    path: "product",
    name: "Product",
    layout: "/dashboard",
    access: "products",
  },
  {
    path: "vendor",
    name: "Vendor",
    layout: "/dashboard",
    access: "storevendor",
  },
  {
    path: "customer",
    name: "Customer",
    layout: "/dashboard",
    access: "customers",
  },
  {
    path: "order",
    name: "Order",
    layout: "/dashboard",
    access: "orders",
  },
  {
    path: "review",
    name: "Review",
    layout: "/dashboard",
    access: "review",
  },
  {
    path: "usersWithRole",
    name: "User with role",
    layout: "/dashboard",
    access: "userswithrole",
  },
];
