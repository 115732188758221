import React, { useContext } from "react";

const AppContext = React.createContext(null);

export const useHistory = () => useContext(AppContext);

const AppProvider = ({ history, children }) => {
  return <AppContext.Provider value={history}>{children}</AppContext.Provider>;
};

export default AppProvider;
