export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_ARTICLE_SUCCESS = "ADD_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const ADD_ARTICLE_VIEW = "ADD_ARTICLE_VIEW";
export const ADD_ARTICLE_VIEW_SUCCESS = "ADD_ARTICLE_VIEW_SUCCESS";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const PUBLISH_ARTICLE = "PUBLISH_ARTICLE";
export const PUBLISH_ARTICLE_SUCCESS = "PUBLISH_ARTICLE_SUCCESS";
export const LIST_ARTICLE_FAILED = "LIST_ARTICLE_FAILED";
export const ADD_UPDATE_ARTICLE_FAILED = "ADD_UPDATE_ARTICLE_FAILED";
export const ADD_FLAG = "ARTICLE/ADD_FLAG";
export const ADD_FLAG_SUCCESS = "ARTICLE/ADD_FLAG_SUCCESS";
export const TRASH_ARTICLE = "ARTICLE/TRASH_ARTICLE";
export const TRASH_ARTICLE_SUCCESS = "ARTICLE/TRASH_ARTICLE_SUCCESS";

export const RESET_ARTICLE = "RESET_ARTICLE";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";

export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";

export const ARTICLE_FAILED = "ARTICLE_FAILED";

export const UPDATE_ARTICLE_PRIORITY = "UPDATE_ARTICLE_PRIORITY";
export const UPDATE_ARTICLE_PRIORITY_SUCCESS =
  "UPDATE_ARTICLE_PRIORITY_SUCCESS";

export const GET_ARTICLE_INFO = "GET_ARTICLE_INFO";
export const GET_ARTICLE_INFO_SUCCESS = "GET_ARTICLE_INFO_SUCCESS";
