import React, { useRef, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { BiPaperclip } from "react-icons/bi";
import imageCompression from "browser-image-compression";

import TextareaField from "../../../../components/UI/TextareaField";
import FilePreview from "../../../../components/UI/FilePreview";
import { post } from "../../../../store/lib/Api";
import { errorResponse } from "../../../../store/sagas/auth";

const Messenger = ({ onSend, userType }) => {
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSend({ userType, message, files });
  };

  const onUploadFiles = async (filesArray) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const heifFiles = [];
    const normalFiles = [];
    const otherFiles = [];

    filesArray.forEach((file) => {
      const fileType = file.name.split(".").at(-1).toLowerCase();
      if (fileType === "heif" || fileType === "heic") {
        heifFiles.push(file);
      } else if (
        ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileType)
      ) {
        normalFiles.push(file);
      } else {
        otherFiles.push(file);
      }
    });

    const uploadNormalFiles = async () => {
      const uploadedNormalFiles = [];
      if (!normalFiles) return [];

      const formData = new FormData();
      for (let i = 0; i < normalFiles.length; i++) {
        const file = normalFiles[i];
        try {
          const compressedFile = await imageCompression(file, options);
          formData.append("media", compressedFile);
        } catch (error) {
          errorResponse(error);
        }
      }

      try {
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedNormalFiles.push(...(result?.data || []));
      } catch (error) {
        errorResponse(error);
      }

      return uploadedNormalFiles;
    };

    const convertHeifFiles = async () => {
      const uploadedHeifFiles = [];
      if (!heifFiles) return [];

      const formData = new FormData();
      for (let i = 0; i < heifFiles.length; i++) {
        const file = heifFiles[i];
        formData.append("media", file);
      }

      try {
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedHeifFiles.push(...(result?.data || []));
      } catch (error) {
        errorResponse(error);
      }
      return uploadedHeifFiles;
    };

    const uploadOtherFiles = async () => {
      const uploadedOtherFiles = [];
      if (!otherFiles.length) return [];

      const formData = new FormData();
      for (let i = 0; i < otherFiles.length; i++) {
        const file = otherFiles[i];
        formData.append("media", file);
      }

      try {
        const result = await post("/users/upload-file", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedOtherFiles.push(...(result?.data || []));
      } catch (error) {
        errorResponse(error);
      }

      return uploadedOtherFiles;
    };

    const [uploadedNormalFiles, convertedHeifFiles, uploadedOtherFiles] =
      await Promise.all([
        uploadNormalFiles(),
        convertHeifFiles(),
        uploadOtherFiles(),
      ]);

    setFiles((prevImages) => [
      ...prevImages,
      ...uploadedNormalFiles,
      ...convertedHeifFiles,
      ...uploadedOtherFiles,
    ]);
  };

  return (
    <form className="d-flex flex-column pl-3 mt-5 w-100" onSubmit={onSubmit}>
      <TextareaField
        value={message}
        label="Message"
        onChange={(value) => setMessage(value)}
        required
      />
      <div className="d-flex justify-content-between align-items-start">
        <button type="submit" className="send-btn">
          <div className="svg-wrapper-1">
            <RiSendPlaneFill className="!w-5" />
          </div>
          <span>Send</span>
        </button>
        <div className="d-flex flex-wrap" style={{ gap: 10, maxWidth: "65%" }}>
          <input
            ref={inputRef}
            hidden
            className="form-control"
            type="file"
            onChange={(event) => {
              const filesArray = Array.from(event.target.files);
              onUploadFiles(filesArray);
            }}
          />
          {files?.map((file) =>
            file.url.includes("/raw/") ? (
              <FilePreview withoutImage={true} file={file} />
            ) : (
              <img
                className="mr-3"
                style={{ width: 200, height: 200 }}
                src={file?.url}
              />
            )
          )}
          <BiPaperclip fontSize={16} onClick={() => inputRef.current.click()} />
        </div>
      </div>
    </form>
  );
};

export default Messenger;
