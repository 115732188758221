import React, { useEffect, useState } from "react";

const StnImage = ({
  src,
  className,
  placeholder,
  alt,
  width = 100,
  height = 100,
}) => {
  const [source, setSource] = useState();

  useEffect(() => {
    setSource(src ?? placeholder);
  }, [src]);

  return (
    <img
      alt={alt}
      src={source}
      width={width}
      height={height}
      className={className}
      onError={() => setSource(placeholder)}
    />
  );
};

export default StnImage;
