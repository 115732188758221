import React, { useEffect, useState } from "react";
import { BiLike } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import { Button, Row, Col } from "reactstrap";
import { FaEye, FaTrash, FaEdit } from "react-icons/fa";
import { Dialog, DialogContent } from "@material-ui/core";

import "./style.css";
import Expandable from "../../UI/Expandable";
import placeholder from "../../../assets/img/Placeholder.png";
import { useHistory } from "../../../contexts/AppProvider";
import {
  getArticleInfo,
  getArticleInfoSuccess,
  publishArticle,
} from "../../../store/actions/articleActions";

const PopUpModal = ({
  open,
  articleId,
  onCloseModal,
  article,
  handleEdit,
  handleDelete,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.auth);
  const { articleInfo } = useSelector((state) => state.article);
  const articleData = articleInfo ?? article;

  const onClose = () => {
    onCloseModal();
    dispatch(getArticleInfoSuccess(null));
    if (articleId) {
      history.push(history.location.pathname);
    }
  };

  useEffect(() => {
    if (articleId) {
      dispatch(getArticleInfo(articleId));
    }
  }, [articleId]);

  const onPublish = () => {
    dispatch(publishArticle(articleData._id, token));
  };

  if (!articleData) return <></>;

  return (
    <Dialog
      maxWidth="xl"
      open={open || (articleId && !!articleData)}
      onClose={onClose}
      aria-hidden={false}
      aria-label="Preview Modal"
    >
      <Close onClick={onClose} className="close-btn" />
      <DialogContent className="pb-4 mt-4">
        <Row>
          <Col sm="12" md="5">
            <div className="images">
              <img
                className="w-100 h-100 rounded"
                src={
                  articleData?.cover?.url
                    ? `${articleData?.cover?.url}`
                    : placeholder
                }
                alt=""
              />
            </div>
          </Col>
          <Col sm="12" md="7">
            <div className="product">
              <p className="mt-2 mb-1">
                <em>
                  <strong>Title:</strong> {articleData?.name.toUpperCase()}
                </em>
              </p>
              <p className="mt-0">
                <em>
                  <strong>Description:</strong>
                  <Expandable
                    text={articleData?.description.toUpperCase()}
                    lines={4}
                    danger
                  />
                </em>
              </p>
              <h2>
                {role === "admin" && (
                  <div style={{ minWidth: "120px", maxWidth: "120px" }}>
                    {articleData?.status === "published" ? (
                      <Button
                        className="article-btn unpublish"
                        onClick={onPublish}
                      >
                        Unpublish
                      </Button>
                    ) : (
                      <Button
                        className="article-btn publish"
                        onClick={onPublish}
                      >
                        Publish
                      </Button>
                    )}
                  </div>
                )}
                <div className="d-flex align-items-center mt-2 text-dark">
                  <FaEye size={"20px"} className="mr-2" /> {articleData?.views}
                </div>
                <div className="d-flex align-items-center text-dark">
                  <BiLike size={"20px"} className="mr-2" /> {articleData?.likes}
                </div>
              </h2>
              <p className="desc">{articleData?.content}</p>
              <div className="d-flex gap-4">
                <button
                  onClick={() => handleEdit(articleData?._id)}
                  className="btn-sm btn-info editModal"
                >
                  Edit <FaEdit />
                </button>
                {role === "admin" && (
                  <button
                    onClick={() => handleDelete(articleData)}
                    className="btn-sm btn-danger deleteModal"
                  >
                    Delete <FaTrash />
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpModal;
