import React from "react";
import { Delete } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { FormGroup } from "react-bootstrap";
import { CKEditor } from "ckeditor4-react";

const VideoInstance = ({
  index,
  video,
  control,
  handleRemoveVideo,
  errors,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Video #{index + 1}</h4>
        <Delete
          className="text-danger"
          onClick={() => handleRemoveVideo(index)}
        />
      </div>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Video Title:</label>
        </div>
        <Controller
          name={`videos[${index}].title`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Title"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.videos?.[index]?.title?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.title?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Video Description:</label>
        </div>
        <Controller
          name={`videos[${index}].description`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CKEditor
              initData={value}
              config={{
                versionCheck: false,
                placeholder: "Write your here...",
                height: 300,
                extraPlugins: "font",
                font_defaultLabel: "Helvetica",
                font_names:
                  "Comic Sans MS;Courier New;Helvetica;Impact;Tahoma;Times New Roman;Verdana;",
                fontSize_defaultLabel: "17px",
                fontSize_sizes:
                  "8/8px;10/10px;12/12px;14/14px;16/16px;17/17px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;",
              }}
              onChange={(event) => onChange(event.editor.getData())}
              onInstanceReady={(evt) => {
                const editor = evt.editor;
                editor.document.getBody().setStyle("font-size", "17px");
              }}
            />
          )}
        />
        {errors?.videos?.[index]?.description?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.description?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Youtube Video URL:</label>
        </div>
        <Controller
          name={`videos[${index}].video`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Video URL"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.videos?.[index]?.video?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.video?.message}
          </div>
        )}
        {video?.video && (
          <iframe
            className="rounded mt-2"
            width={250}
            height={140}
            src={video?.video}
          />
        )}
      </FormGroup>
    </div>
  );
};

export default VideoInstance;
