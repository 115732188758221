import React, { useMemo, useState } from "react";
import { Collapse } from "@material-ui/core";

import FilePreview from "../../../components/UI/FilePreview";
import StnImage from "../../../components/UI/Image";
import { profilePlaceholder } from "../../../utilities";

const DeliveryData = (props) => {
  const { orderDetails, isVendor, isClient } = props;
  const [collapse, setCollapse] = useState(false);

  const client = orderDetails?.user;
  const vendor = orderDetails?.owner;
  const review = orderDetails?.review;
  const delivery = orderDetails?.orderDelivery;

  const reviewPreview = useMemo(
    () => (
      <div className="mt-4">
        <div className="py-3 px-3 text-xl font-semibold">
          {client?.firstName} {client?.lastName}'s pictures of delivered product
        </div>
        <div className="border-t-2 border-gray-300 py-3 px-3">
          <div style={{ gap: 12 }} className="d-flex align-items-start">
            <StnImage
              className="rounded-circle"
              src={client?.profilePic}
              placeholder={profilePlaceholder}
              width={40}
              height={40}
              alt="Sender Profile"
            />
            <div>
              <div className="text-lg font-semibold">
                {client?.firstName} {client?.lastName}'s message
              </div>
              <div
                className="font-halvatica"
                dangerouslySetInnerHTML={{
                  __html: review?.vendor?.description,
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            ATTACHMENTS
            <div style={{ gap: 12 }} className="d-flex flex-wrap mt-2">
              {review?.attachments?.map((attachment, index) => (
                <FilePreview key={index} file={attachment} />
              ))}
            </div>
          </div>
          {review?.video?.url && (
            <div className="mt-4">
              VIDEO
              <div style={{ width: "15rem" }} className="d-flex flex-wrap mt-2">
                <div
                  style={{ height: "11rem" }}
                  className="d-flex justify-content-center align-items-center border rounded-lg overflow-hidden cursor-pointer"
                >
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={review?.video?.url}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ),
    []
  );

  const deliveryPreview = useMemo(
    () => (
      <div className="mt-4">
        <div className="py-3 px-3 text-xl font-semibold">
          {vendor?.firstName} {vendor?.lastName}'s pictures of delivered product
        </div>
        <div className="border-t-2 border-gray-300 py-3 px-3">
          <div style={{ gap: 12 }} className="d-flex align-items-start">
            <StnImage
              className="rounded-circle"
              src={vendor?.profilePic}
              placeholder={profilePlaceholder}
              width={40}
              height={40}
              alt="Sender Profile"
            />
            <div>
              <div className="text-lg font-semibold">
                {vendor?.firstName} {vendor?.lastName}'s message
              </div>
              <div
                className="font-halvatica"
                dangerouslySetInnerHTML={{ __html: delivery.message }}
              />
            </div>
          </div>
          <div className="mt-4">
            ATTACHMENTS
            <div style={{ gap: 12 }} className="d-flex flex-wrap mt-2">
              {delivery?.attachments?.map((attachment, index) => (
                <FilePreview key={index} file={attachment} />
              ))}
            </div>
          </div>
          {delivery?.video?.url && (
            <div className="mt-4">
              VIDEO
              <div style={{ width: "15rem" }} className="d-flex flex-wrap mt-2">
                <div
                  style={{ height: "11rem" }}
                  className="d-flex justify-content-center align-items-center border rounded-lg overflow-hidden cursor-pointer"
                >
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={delivery?.video?.url}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ),
    []
  );

  return (
    <div className="py-3">
      <span className="cursor-pointer" onClick={() => setCollapse(!collapse)}>
        See details
      </span>
      <Collapse in={collapse} unmountOnExit>
        {isClient ? reviewPreview : <></>}
        {isClient ? deliveryPreview : <></>}
        {isVendor ? deliveryPreview : <></>}
        {isVendor ? reviewPreview : <></>}
      </Collapse>
    </div>
  );
};

export default DeliveryData;
