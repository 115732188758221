import React, { useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Rating from "@material-ui/lab/Rating";
import { Collapse } from "@material-ui/core";
import StnImage from "../../components/UI/Image";
import { profilePlaceholder } from "../../utilities";

const ReviewCard = ({ activity, item, history }) => {
  const user = item?.clientInfo ?? {};
  const [collapse, setCollapse] = useState(false);

  const handleClickActivity = (id) => {
    history.push(`/dashboard/orders/activity/${id}`, id);
  };

  const reviewImages = useMemo(() => {
    return item?.attachments?.map((image, i) => {
      return (
        image?.url && (
          <div
            className="d-flex justify-content-center align-items-center border rounded overflow-hidden cursor-pointer"
            style={{ width: "12rem", height: "11rem" }}
          >
            <img src={image?.url} alt="Product Image" className="img-fluid" />
          </div>
        )
      );
    });
  }, [item?.attachments]);

  const reviewVideo = useMemo(() => {
    const video = item?.video;
    return (
      video?.url && (
        <div
          className="d-flex justify-content-center align-items-center border rounded overflow-hidden cursor-pointer"
          style={{ width: "15rem", height: "11rem" }}
        >
          <video
            className="w-100 h-100"
            src={video?.url}
            controls
            controlsList="nodownload"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )
    );
  }, [item?.video]);

  return (
    <div
      className={`d-flex flex-column flex-sm-row gap-8 gap-sm-4 align-items-start ${
        activity ? "mt-2" : ""
      }`}
    >
      <div className="d-flex flex-column flex-sm-row gap-8">
        {!activity && (
          <div className="d-flex gap-8">
            <StnImage
              className="rounded"
              nextImage={false}
              src={user?.profilePic}
              placeholder={profilePlaceholder}
              width={50}
              height={50}
              alt="Profile"
            />
            <div>
              <div className="fs-5" style={{ minWidth: 100, maxWidth: 150 }}>
                {user?.firstName} {user?.lastName}
              </div>
              {user?.storeName && <div>Store: {user?.storeName}</div>}
            </div>
          </div>
        )}
        <div className="d-flex gap-8 d-sm-none">
          <div>
            <Rating
              classes={{ disabled: "opacity-100" }}
              disabled
              value={item?.overallExperience?.rating}
              size="medium"
            />
            {!activity && (
              <p
                className="text-blue cursor-pointer font-weight-600"
                onClick={() => handleClickActivity(item?.orderItemId)}
              >
                #{item?.orderItem?.orderNo}
              </p>
            )}
          </div>
          <span>{moment(item?.updatedAt).fromNow()}</span>
        </div>
      </div>
      <div>
        <div className="d-none d-sm-flex gap-8">
          <div>
            <Rating
              classes={{ disabled: "opacity-100" }}
              disabled
              value={item?.overallExperience?.rating}
              size="medium"
            />
            {!activity && (
              <p
                className="text-blue cursor-pointer font-weight-600"
                onClick={() => handleClickActivity(item?.orderItemId)}
              >
                #{item?.orderItem?.orderNo}
              </p>
            )}
          </div>
          <span>{moment(item?.updatedAt).fromNow()}</span>
        </div>
        <div className="mt-3 text-break">
          {item?.overallExperience?.description}
        </div>
        <span
          onClick={() => setCollapse(!collapse)}
          className="text-muted cursor-pointer"
          style={{ fontSize: "0.875rem" }}
        >
          {collapse ? "See less" : "See more"}
        </span>
        <Collapse in={collapse}>
          <div className="d-flex flex-wrap gap-8">
            {reviewImages}
            {reviewVideo}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default withRouter(ReviewCard);
