import React, { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";

const MediaViewModal = ({ product, currentIndex, onHide }) => {
  const [index, setIndex] = useState(currentIndex ?? 0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let images = [];
  if (product?.productPic != null && product?.productPic?.length > 0) {
    images = product?.productPic.map((pic) => ({
      ...pic,
      url: pic?.url ?? pic?.img,
    }));
  }

  if (product?.video) {
    images.push({
      ...product?.video,
      video: true,
    });
  }

  return (
    <Modal centered show={true} onHide={onHide}>
      <Modal.Body style={{ height: "fit-content", padding: "0px" }}>
        <div className="flex justify-center items-center">
          <Carousel
            activeIndex={index}
            className={"preview"}
            onSelect={handleSelect}
            indicators={false}
            controls={true}
            prevLabel=""
            nextLabel=""
            interval={null}
          >
            {images?.length > 0 &&
              images?.map((image, index) => {
                const { url, video } = image;
                return (
                  <Carousel.Item key={index} className="cursor-pointer">
                    {video ? (
                      <video
                        src={url}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        controls
                        controlsList="nodownload"
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        className="rounded"
                        src={url}
                        width={600}
                        height={600}
                        alt={index}
                        draggable={false}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MediaViewModal;
