import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { PlayArrow } from "@material-ui/icons";

import MediaViewModal from "./MediaViewModal";
import { cloudinaryImage } from "../../utilities";

const ProductCarousel = (props) => {
  const { product, type } = props;

  const [media, setMedia] = useState(null);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let images = [];
  if (product?.productPic != null && product?.productPic?.length > 0) {
    images = product.productPic.map((pic) => ({
      ...pic,
      url: pic?.url || pic?.img,
    }));
  }

  if (product?.video) {
    images.push({
      ...product?.video,
      video: true,
    });
  }

  return (
    <div className={`new-product-card-main ${type}`}>
      <div className="product-content-wrapper" style={{ overflow: "hidden" }}>
        <div className={`product-container ${type}`}>
          <div className="product-slider" onClick={(e) => e.stopPropagation()}>
            <Carousel
              activeIndex={index}
              className={type === "preview" ? "preview" : ""}
              controls={type === "preview"}
              interval={null}
              indicators={false}
              prevLabel=""
              nextLabel=""
              onSelect={handleSelect}
            >
              {images.length > 0 &&
                images.map((image, index) => {
                  let { url, video } = image;
                  return (
                    <Carousel.Item
                      key={index}
                      className="cursor-pointer"
                      onClick={() => setMedia({ url, video })}
                    >
                      {video ? (
                        <div
                          className={`media ${
                            type === "preview" ? "preview" : ""
                          } d-flex justify-content-center align-items-center`}
                        >
                          {type === "preview" ? (
                            <video
                              src={url}
                              alt={`Product Video`}
                              controls
                              controlslist="nodownload"
                              preload="metadata"
                              width="100%"
                              style={{ height: 300 }}
                            />
                          ) : (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={cloudinaryImage({ url, thumbnail: true })}
                              alt="Thumbnail"
                            />
                          )}
                        </div>
                      ) : (
                        <img
                          className={`media ${type}`}
                          src={url}
                          alt={index}
                          draggable={false}
                        />
                      )}
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </div>
        </div>
        <div className="slider-image-select-wrapper mt-2">
          {images.length > 0 &&
            images.map((image, index) => {
              let { url, video } = image;
              return (
                <div
                  key={index}
                  className={
                    index === index
                      ? `active-image-btn ${type}`
                      : `image-btn ${type}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelect(index);
                  }}
                >
                  {video ? (
                    <div className="product-video-wrapper">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={cloudinaryImage({ url, thumbnail: true })}
                        alt="Selected"
                      />
                      <PlayArrow className="play" />
                    </div>
                  ) : (
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={url}
                      alt="Selected"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {media && (
        <MediaViewModal
          product={product}
          currentIndex={index}
          onHide={() => setMedia(null)}
        />
      )}
    </div>
  );
};

export default ProductCarousel;
