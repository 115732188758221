import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import { Breadcrumb, Container, Spinner } from "react-bootstrap";
import { Button, Chip } from "@material-ui/core";

import Messenger from "./Common/Messenger";
import InvoiceDialog from "../Invoice";
import CustomizedActivity from "./CustomizedActivity";
import PreviewModal from "../PreviewModal";
import * as orderActions from "../../../store/actions/orderActions";
import { get } from "../../../store/lib/Api";
import {
  ACTIVITY_TYPE,
  allStatus,
  feeCalculator,
  isArray,
  isEmpty,
  OrderStatus,
} from "../../../utilities";
import { getConfig } from "../../../store/actions/settingActions";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { errorResponse } from "../../../store/sagas/auth";
import "./activity.scss";

const OrderActivity = ({ location }) => {
  const dispatch = useDispatch();
  const [dataHtml, setDataHtml] = useState("");
  const { width } = useScreenResolution();
  const [tab, setTab] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, user: admin } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.setting);
  const { singleOrder } = useSelector((state) => state.order) ?? {};
  const {
    _id,
    orderActivity,
    orderNo,
    totalPrice,
    product,
    user,
    owner,
    orderConfirmed,
    productConfirmed,
    addons,
    cancelRequest,
    status,
  } = singleOrder ?? {};

  const id = location?.pathname.split("/").at(-1);

  useEffect(() => {
    dispatch(getConfig());
    dispatch(orderActions.getSingleOrder(id, token));
  }, [id]);

  const handleGeneratePdf = async (isClient) => {
    try {
      setLoading(true);
      const downloadInvoice = await get(
        `/order/downloadInvoice/${id}?userType=${
          isClient ? "client" : "vendor"
        }&accessedBy=admin`,
        { Authorization: `Bearer ${token}` }
      );

      setDataHtml(downloadInvoice);
      setShowInvoice(true);
      setLoading(false);
    } catch (error) {
      errorResponse(error);
      setLoading(false);
      setShowInvoice(false);
    }
  };

  const onContact = (email) => {
    get(`/users/hubspotContact?email=${email}`, {
      Authorization: `Bearer ${token}`,
    }).then((data) => {
      if (data?.contactId) {
        window.open(
          `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_ID}/contact/${data?.contactId}`,
          "_blank"
        );
      }
    });
  };

  const onWithdrawRequest = () => {
    dispatch(
      orderActions.createActivity(
        {
          orderItemId: _id,
          type: ACTIVITY_TYPE.WITHDRAW_CANCELLATION,
        },
        token
      )
    );
  };

  if (Array.isArray(orderActivity)) {
    const indexOfOrderDelivered = orderActivity.findIndex(
      (item) => item.type === ACTIVITY_TYPE.ORDER_DELIVERED
    );

    const indexOfDeliverOrder = orderActivity.findIndex(
      (item) => item.type === ACTIVITY_TYPE.DELIVER_ORDER
    );

    orderActivity.sort((a, b) => {
      if (indexOfOrderDelivered !== -1) {
        if (
          a.type === ACTIVITY_TYPE.DELIVER_ORDER &&
          b.type === ACTIVITY_TYPE.ORDER_DELIVERED
        )
          return -1;
        if (
          b.type === ACTIVITY_TYPE.DELIVER_ORDER &&
          a.type === ACTIVITY_TYPE.ORDER_DELIVERED
        )
          return 1;
      }

      if (indexOfOrderDelivered === -1) {
        if (a.type === ACTIVITY_TYPE.DELIVER_ORDER) return 1;
        if (b.type === ACTIVITY_TYPE.DELIVER_ORDER) return -1;
      }

      if (indexOfDeliverOrder === -1) {
        if (a.type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE) return 1;
        if (b.type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE) return -1;
      }

      if (
        a.type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE &&
        b.type === ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED
      )
        return -1;
      if (
        b.type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE &&
        a.type === ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED
      )
        return 1;

      return String(a.createdAt).localeCompare(String(b.createdAt));
    });
  }

  const addonTotal = addons?.reduce(
    (total, item) => total + item?.totalPrice,
    0
  );

  const marketingFee = feeCalculator({
    percent: config?.marketingFee,
    total: totalPrice,
    addonTotal,
  });
  const maintenanceFee = feeCalculator({
    percent: config?.maintenanceFee,
    total: totalPrice,
    addonTotal,
  });
  const technologyFee = feeCalculator({
    percent: config?.technologyFee,
    total: totalPrice,
    addonTotal,
  });
  const workerFund = feeCalculator({
    percent: config?.workerFund,
    total: totalPrice,
    addonTotal,
  });

  const orderTotal = (totalPrice + (addonTotal || 0)).toFixed(2);

  const vendorOrderTotal = (
    (orderTotal || 0) -
    (marketingFee + maintenanceFee + technologyFee + workerFund)
  ).toFixed(2);

  const grossProfit = Math.abs(
    Math.floor(
      orderTotal > parseInt(vendorOrderTotal)
        ? orderTotal - parseInt(vendorOrderTotal)
        : parseInt(vendorOrderTotal) - orderTotal
    )
  );

  const onCancel = async () => {
    const { isConfirmed } = await swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to cancel this order?",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonText: "Yes Confirm",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
    });
    if (isConfirmed) {
      const { value: comment, isConfirmed } = await swal.fire({
        html: `<h2 clas="text-dark">Admin reason for cancelling</h2><h5>(For internal use only)</h5>`,
        input: "textarea",
        showCancelButton: true,
        cancelButtonColor: "gray",
        confirmButtonColor: "#007BFF",
        reverseButtons: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
      });
      if (isConfirmed) {
        dispatch(orderActions.cancelOrder({ _id, comment }, token));
      }
    }
  };

  const onWithdraw = async () => {
    const { isConfirmed } = await swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to withdraw the cancellation request?",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonText: "Yes Confirm",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
    });
    if (isConfirmed) {
      onWithdrawRequest();
    }
  };

  const onApproveRequest = async () => {
    const { value: comment, isConfirmed } = await swal.fire({
      html: `<h2 clas="text-dark">Admin reason for approving cancellation</h2><h5>(For internal use only)</h5>`,
      input: "textarea",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
    if (isConfirmed) {
      dispatch(orderActions.approveCancelOrder({ _id, comment }, token));
    }
  };

  const onSend = ({ userType, message, files }) => {
    dispatch(
      orderActions.createActivity(
        {
          orderItemId: _id,
          type: ACTIVITY_TYPE.NEW_MESSAGE,
          message,
          attachments: files,
          senderId: admin,
          receiverId: userType == "client" ? user?._id : owner?._id,
          forAdmin: true,
          forClient: userType == "client",
          forVendor: userType == "vendor",
          clientId: user?._id,
          vendorId: owner?._id,
        },
        token
      )
    );
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "100vh" }}
      >
        <Spinner height={50} width={50} animation="border" role="status" />
      </div>
    );
  }

  return (
    <>
      <div className="activity mt-3 mx-3">
        <h2 className="text-dark">Order Activity</h2>
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard/orders">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            #{orderNo}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="activity-header d-flex justify-content-between align-items-stretch mt-3"
          style={{ gap: 20 }}
        >
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex w-100">
              {product?.productPic?.[0]?.url && (
                <img
                  style={{ maxHeight: 150, maxWidth: 150, borderRadius: 10 }}
                  src={product?.productPic?.[0]?.url}
                  alt={product?.sku}
                />
              )}
              <div className="ml-3 d-flex flex-column justify-content-between align-items-start w-100">
                <div className="d-flex flex-column justify-content-between align-items-start w-100">
                  <div
                    onClick={() => setProductInfo(product)}
                    className="cursor-pointer font-weight-bold"
                    dangerouslySetInnerHTML={{ __html: product?.name }}
                  />
                  {allStatus.map(
                    (s) =>
                      status === s.status && (
                        <Chip
                          className="mt-2"
                          size="small"
                          style={{ background: s.color, color: "white" }}
                          label={s?.label}
                        />
                      )
                  )}
                </div>
              </div>
              <Chip
                className="profit-t"
                variant="outlined"
                style={{ fontSize: 15, width: 200 }}
                label={`Gross Profit: $${grossProfit}`}
              />
            </div>
          </div>
          <div
            className="d-flex flex-column w-100 justify-content-between align-items-end"
            style={{ gap: 10 }}
          >
            <Chip
              className="profit-bt"
              variant="outlined"
              style={{ fontSize: 15, width: 200 }}
              label={`Gross Profit: $${grossProfit}`}
            />
            <div className="actions d-flex">
              <Button
                style={{ background: "#3B84CD" }}
                className="customer-btn px-4 border-0 text-white"
                onClick={() => onContact(user?.email)}
              >
                Contact customer
              </Button>
              <Button
                style={{ background: "#0B76E0" }}
                className="vendor-btn px-4 border-0 text-white"
                onClick={() => onContact(owner?.email)}
              >
                Contact vendor
              </Button>
              {(status === OrderStatus.STARTED ||
                status == OrderStatus.INPROGRESS) && (
                <Button
                  style={{ background: "#dd4253" }}
                  className="vendor-btn btn-danger px-4 border-0 text-white"
                  onClick={onCancel}
                >
                  Cancel Order
                </Button>
              )}
              {(status == OrderStatus.STARTED ||
                status == OrderStatus.INPROGRESS) &&
                cancelRequest &&
                isEmpty(cancelRequest?.approved) &&
                (!orderConfirmed || !productConfirmed) && (
                  <Button
                    style={{ background: "black" }}
                    className="vendor-btn mr-2 px-4 border-0 text-white"
                    onClick={onApproveRequest}
                  >
                    Approve Cancel Request
                  </Button>
                )}
              {(status == OrderStatus.STARTED ||
                status == OrderStatus.INPROGRESS) &&
                cancelRequest &&
                isEmpty(cancelRequest?.withdrawn) && (
                  <Button
                    style={{ background: "gray" }}
                    className="vendor-btn mr-2 px-4 border-0 text-white"
                    onClick={onWithdraw}
                  >
                    Withdraw Cancellation
                  </Button>
                )}
            </div>
          </div>
        </div>
        <div className="tabs">
          <Chip
            label="Customer"
            style={{
              fontSize: 18,
              backgroundColor: !tab || tab === "customer" ? "black" : "",
              color: !tab || tab === "customer" ? "white" : "",
            }}
            onClick={() => setTab("customer")}
          />
          <Chip
            label="Vendor"
            style={{
              fontSize: 18,
              backgroundColor: tab === "vendor" ? "black" : "",
              color: tab === "vendor" ? "white" : "",
            }}
            onClick={() => setTab("vendor")}
          />
        </div>
        <div className="d-flex mt-2" style={{ gap: "10px" }}>
          <div
            className={`activity-section ${
              width < 768 || width < 992
                ? !tab || tab === "customer"
                  ? "customer show"
                  : ""
                : "show"
            }`}
          >
            <div></div>
            <component />
            <component />
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                <b>
                  Customer ({user?.firstName} {user?.lastName})
                </b>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="text-lg">${orderTotal}</div>
                <div
                  className="invoice"
                  onClick={() => handleGeneratePdf(true)}
                >
                  View Invoice
                </div>
              </div>
            </div>
            {orderActivity?.map((activity, i) => (
              <CustomizedActivity
                key={i}
                forTheClient={true}
                orderDetails={singleOrder}
                activity={activity}
                product={product}
              />
            ))}
            <Messenger onSend={onSend} userType="client" />
          </div>
          <div
            className={`activity-section ${
              width < 768 || width < 992
                ? tab === "vendor"
                  ? "vendor show"
                  : ""
                : "show"
            }`}
          >
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                <b>
                  Vendor ({owner?.firstName} {owner?.lastName})
                </b>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="text-lg">${vendorOrderTotal}</div>
                <div
                  className="invoice"
                  onClick={() => handleGeneratePdf(false)}
                >
                  View Invoice
                </div>
              </div>
            </div>
            {orderActivity?.map((activity, i) => (
              <CustomizedActivity
                key={i}
                forTheVendor={true}
                orderDetails={singleOrder}
                activity={activity}
                product={product}
              />
            ))}
            <Messenger onSend={onSend} userType="vendor" />
          </div>
        </div>
        {productInfo && (
          <PreviewModal
            onHide={() => setProductInfo(null)}
            productInfo={productInfo}
          />
        )}
      </div>
      {showInvoice && (
        <InvoiceDialog
          dataHtml={dataHtml}
          showModel={showInvoice}
          onClose={() => {
            setShowInvoice(false);
            setDataHtml("");
          }}
        />
      )}
    </>
  );
};

export default OrderActivity;
