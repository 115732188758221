import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Table,
} from "reactstrap";
import { TiImage } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Paginator from "../Pagination";
import ArticleList from "./ArticleList";
import Loading from "../../utilities/loading";
import SearchField from "../../components/SearchFields";
import Modal from "../../components/Modal/ArticleModal";
import Header from "../../components/Headers/DefaultHeader";
import DialogModal from "../../components/Modal/DialogModal";
import Selector from "../../components/SearchFields/Selector";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import * as Const from "../../utilities";
import * as articleAction from "../../store/actions/articleActions";
import * as categoryActions from "../../store/actions/categoryActions";

const Articles = ({ history }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({});
  const [note, setNote] = useState("");
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [articleId, setArticleId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [flagDialog, setFlagDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(false);

  const searchParams = new URLSearchParams(history.location.search);
  const id = searchParams.get("id");

  const { token, user, role } = useSelector((state) => state.auth);
  const { articles, totalArticle, loading } = useSelector(
    (state) => state.article
  );
  const { categories } = useSelector((state) => state.category);

  const categoryList = categories?.map((category) => ({
    label: category?.name,
    value: category?._id,
  }));

  useEffect(() => {
    if (role === "admin") {
      dispatch(articleAction.getArticle({ deleted: false, page: 1 }));
    } else if (token) {
      setQuery({ ...query, memberId: user });
      dispatch(
        articleAction.getArticle({
          memberId: user,
          deleted: false,
          page: 1,
        })
      );
    }

    dispatch(categoryActions.getCategories());
  }, []);

  useEffect(() => {
    if (!Const.isEmpty(query)) {
      dispatch(articleAction.getArticle({ deleted: false, ...query }));
    }
  }, [query]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setQuery({ ...query, page: 1, searchText: value });
    }, 500),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setArticleId(id);
    }, 1000);
  }, [id]);

  const onPageChange = (page) => {
    setQuery({ ...query, page });
  };

  const onOpenModal = (article) => {
    setOpenModal(true);
    setSelectedArticle(article);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddFlag = (article) => {
    if (article) {
      dispatch(
        articleAction.addFlag(
          article?._id,
          { flag: !article?.flag, note },
          token
        )
      );
    } else {
      dispatch(
        articleAction.addFlag(
          selectedArticle?._id,
          { flag: !selectedArticle?.flag, note },
          token
        )
      );
    }
    handleClose();
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    dispatch(
      articleAction.trashArticle(selectedArticle._id, { deleted: true }, token)
    );
  };

  const handleEdit = (id) => {
    history.push("/dashboard/add-article", id);
  };

  const handleAdd = () => {
    history.push("/dashboard/add-article");
  };

  const publishArticle = (id) => {
    dispatch(articleAction.publishArticle(id, token));
  };

  const filterSelect = (value) => {
    if (value) {
      setQuery({ ...query, status: value, page: 1 });
    } else {
      setQuery({ ...query, status: null, page: 1 });
    }
  };

  const categorySelect = (value) => {
    if (value) {
      setQuery({ ...query, category: value, page: 1 });
    } else {
      setQuery({ ...query, category: null, page: 1 });
    }
  };

  const onSearch = (value) => {
    debouncedSearch(value);
  };

  const handleRequestSort = (property) => {
    const isAsc = query?.orderBy === property && query?.order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQuery({
      ...query,
      orderBy: property,
      order: isAsc ? "desc" : "asc",
      page: 1,
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (loading == true) {
      return;
    } else {
      if (!result.destination) {
        return;
      }

      const reordered = reorder(
        articles,
        result.source.index,
        result.destination.index
      );

      dispatch(articleAction.getArticlesSuccess(reordered));
      let list = [];
      for (const article of reordered) {
        list.push(article._id);
      }
      dispatch(articleAction.updateArticlePriority(list, token));
    }
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
    },
    { label: `Flag an \n inappropriate` },
    { label: "Note" },
    { label: "Status" },
    { id: "priority", numeric: true, label: "Priority" },
    { id: "views", numeric: true, label: "Views" },
    { id: "likes", numeric: true, label: "Likes" },
    { label: "Category" },
    { label: "Writer Email" },
    { label: "Writer Name" },
    { label: "Writer Address" },
    { id: "publishDate", numeric: false, label: "Published Date" },
    { id: "createdAt", numeric: false, label: "Created Date" },
  ];

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Articles</h3>
              </CardHeader>
              <div className="HeaderWrapper align-items-center flex-wrap">
                <SearchField onChange={onSearch} />
                <div style={{ width: 170 }}>
                  <Selector
                    options={Const.articleSelector}
                    onChange={filterSelect}
                    placeholder="Select status"
                  />
                </div>
                <div style={{ width: 170 }}>
                  <Selector
                    options={categoryList}
                    onChange={categorySelect}
                    placeholder="Select category"
                  />
                </div>
                <Button onClick={handleAdd} type="button">
                  Add Article
                </Button>
              </div>
              <div className="position-relative">
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading-table"
                />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="Table">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Table
                          className={`${
                            loading ? "kb-overlay" : ""
                          } "align-items-center table-flush"`}
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" className="text-center">
                                <TiImage size={"20px"} />
                              </th>
                              {headCells.map((cell, index) => (
                                <th scope="col" key={index}>
                                  {cell.id ? (
                                    <div
                                      style={{ fontSize: "14px" }}
                                      onClick={() =>
                                        handleRequestSort(
                                          cell?.sortingId ?? cell.id
                                        )
                                      }
                                      className="d-flex align-items-center cursor-pointer"
                                    >
                                      {cell.label}{" "}
                                      {orderBy === cell.id ||
                                      orderBy === cell.sortingId ? (
                                        <div className="d-flex flex-column ml-1">
                                          <i
                                            className="fas fa-play"
                                            style={{
                                              fontSize: "8px",
                                              transform: "rotate(-90deg)",
                                              visibility:
                                                (orderBy === cell.id ||
                                                  orderBy === cell.sortingId) &&
                                                order === "asc"
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          />
                                          <i
                                            className="fas fa-play"
                                            style={{
                                              fontSize: "8px",
                                              transform: "rotate(90deg)",
                                              visibility:
                                                (orderBy === cell.id ||
                                                  orderBy === cell.sortingId) &&
                                                order === "desc"
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="d-flex flex-column justify-content-center align-items-center ml-1">
                                          <i
                                            className="fas fa-play mb-1"
                                            style={{
                                              marginLeft: "2px",
                                              fontSize: "8px",
                                              transform: "rotate(-90deg)",
                                              opacity: "0.3",
                                            }}
                                          />
                                          <i
                                            className="fas fa-play"
                                            style={{
                                              fontSize: "8px",
                                              transform: "rotate(90deg)",
                                              opacity: "0.3",
                                              marginLeft: "2px",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {cell.label}
                                    </div>
                                  )}
                                </th>
                              ))}
                              <th></th>
                              <th style={{ fontSize: "14px" }} scope="col">
                                ACTIONS
                              </th>
                            </tr>
                          </thead>
                          <ArticleList
                            articles={articles}
                            publishArticle={publishArticle}
                            openModal={(article) => onOpenModal(article)}
                            handleAddFlag={(selectedArticle) => {
                              if (selectedArticle?.flag) {
                                handleAddFlag(selectedArticle);
                              } else {
                                setFlagDialog(true);
                                setSelectedArticle(selectedArticle);
                              }
                            }}
                            handleDelete={(selectedArticle) => {
                              setDeleteDialog(true);
                              setSelectedArticle(selectedArticle);
                            }}
                            loading={loading}
                          />
                        </Table>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={query?.page ?? 1}
                    perPage={10}
                    onPageChange={onPageChange}
                    total={totalArticle}
                  />
                </nav>
              </CardFooter>
            </Card>
            <Modal
              open={openModal}
              articleId={articleId}
              onCloseModal={onCloseModal}
              article={selectedArticle}
              handleDelete={(selectedArticle) => {
                setDeleteDialog(true);
                setSelectedArticle(selectedArticle);
                setOpenModal(false);
              }}
              handleEdit={handleEdit}
            />
          </div>
        </Row>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      {flagDialog && (
        <DialogModal
          onClose={() => setFlagDialog(false)}
          onConfirm={handleAddFlag}
          title={"Flag an inappropriate"}
        >
          <textarea
            className="form-control"
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogModal>
      )}
    </>
  );
};

export default Articles;
