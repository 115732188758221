import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { Flag } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import Paginator from "../Pagination";
import StnImage from "../../components/UI/Image.jsx";
import Header from "../../components/Headers/DefaultHeader.jsx";
import DialogModal from "../../components/Modal/DialogModal.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";
import Loading from "../../utilities/loading";
import placeholder from "../../assets/img/Placeholder.png";
import * as userActions from "./../../store/actions/userActions";

const Media = () => {
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [note, setNote] = useState(null);
  const [flagDialog, setFlagDialog] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { media, totalMedia, loading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(userActions.getMedia(token, { deleted: false, page }));
  }, [dispatch, token, page]);

  const handleDelete = (media) => {
    setSelectedMedia(media);
    setDeleteMedia(true);
  };

  const handleAddFlag = (media) => {
    if (media?.flag) {
      dispatch(
        userActions.addFlagToMedia(
          media._id,
          { flag: !media.flag, note },
          token
        )
      );
    } else {
      setSelectedMedia(media);
      setFlagDialog(true);
    }
  };

  const onConfirm = () => {
    dispatch(
      userActions.addFlagToMedia(
        selectedMedia._id,
        { flag: !selectedMedia?.flag, note },
        token
      )
    );
    setFlagDialog(false);
  };

  const onConfirmDelete = () => {
    dispatch(
      userActions.trashMedia(selectedMedia._id, { deleted: true }, token)
    );
    setDeleteMedia(false);
  };

  const paginationHandler = (page) => {
    setPage(page);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Media</h3>
              </CardHeader>
              <div className="position-relative">
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading-table"
                />
                <Table
                  className={`${
                    loading ? "kb-overlay" : ""
                  } align-items-center table-flush`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">IMAGE</th>
                      <th scope="col">Owner</th>
                      <th scope="col">
                        Flag an <br /> inappropriate
                      </th>
                      <th>Note</th>
                      <th scope="col">FILE</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {media?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <a
                            href={
                              data?.productPic?.url
                                ? `${process.env.REACT_APP_CLIENT_URL}/products/${data?.sku}`
                                : `${process.env.REACT_APP_CLIENT_URL}/article/${data?.slug}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StnImage
                              src={
                                data?.productPic?.url ?? data?.articlePic?.url
                              }
                              width={150}
                              height={120}
                              placeholder={placeholder}
                              className="rounded"
                              alt={`${data?.name ?? data?.title} - ${index}`}
                            />
                          </a>
                        </td>
                        <td>
                          <span>{data?.owner?.email}</span>
                          <br />
                          <span>
                            {data?.owner?.firstName} {data?.owner?.lastName}
                          </span>
                          <br />
                          {data?.owner?.storeName && (
                            <span>{data?.owner?.storeName}</span>
                          )}
                        </td>
                        <td align="center">
                          <Flag
                            className={data?.flag ? "text-danger" : ""}
                            onClick={() => handleAddFlag(data)}
                          />
                        </td>
                        <td style={{ minWidth: "200px" }}>{data?.note}</td>
                        <td>
                          <a
                            href={
                              data?.productPic?.url ?? data?.articlePic?.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>{" "}
                          |{" "}
                          <a
                            href="javascript:void(0)"
                            onClick={() => handleDelete(data)}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={page}
                    perPage={perPage}
                    onPageChange={paginationHandler}
                    total={totalMedia || 0}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {deleteMedia && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={() => setDeleteMedia(false)}
          onConfirm={onConfirmDelete}
        />
      )}

      {flagDialog && (
        <DialogModal
          onClose={() => setFlagDialog(false)}
          onConfirm={onConfirm}
          title="Flag an inappropriate"
        >
          <textarea
            className="form-control"
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogModal>
      )}
    </>
  );
};

export default Media;
