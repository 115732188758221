import React, { useEffect, useMemo, useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import { Row, Col, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactVideo from "react-video-recorder";
import {
  Dialog,
  Popover,
  TextField,
  Typography,
  FormGroup,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import Camera from "./Camera";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import { AntSwitch } from "../../components/UI/Switch";
import * as productActions from "../../store/actions/productActions";
import * as categoryActions from "../../store/actions/categoryActions";
import { productValidationSchema } from "../../utilities/Schemas/product";
import * as Const from "../../utilities";
import locations from "../../countries.json";
import { post } from "../../store/lib/Api";
import { errorResponse } from "../../store/sagas/auth";

const InputField = ({
  type = "text",
  name,
  label,
  register,
  children,
  errors,
  className,
  ...rest
}) => {
  return (
    <FormGroup
      className={classNames(
        `inline-block w-full ${className ? className : "mb-3"} mx-0`
      )}
    >
      <div className="d-flex">
        <TextField
          {...rest}
          type={type}
          name={name}
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          {...register(name)}
        />
        {children}
      </div>
      {errors?.[name]?.message && (
        <div className="text-danger">{errors?.[name]?.message}</div>
      )}
    </FormGroup>
  );
};

const SelectField = ({
  separate = true,
  name,
  control,
  options,
  placeholder,
  errors,
  isClearable = false,
}) => {
  return (
    <FormGroup className={`${!separate ? "m-0" : "mb-3 w-100 m-0"}`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            isClearable={isClearable}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                height: 40,
                width: "100%",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            placeholder={placeholder}
            onChange={(selectedOption) => {
              field.onChange(selectedOption);
            }}
            options={options}
          />
        )}
      />
      {errors?.[name]?.message && (
        <div className="text-danger">{errors?.[name]?.message}</div>
      )}
    </FormGroup>
  );
};

const countryList = Object.keys(locations).map((key) => ({
  label: key,
  value: key,
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const filter = createFilterOptions();

const MAX_FILES = 5;

const ProductForm = (props) => {
  const {
    products,
    location,
    auth,
    categories,
    getCategories,
    addProduct,
    updateProduct,
    addProductLoading,
    saveFormData,
    formData,
  } = props;

  const productData = products?.find((item) => item._id === location.state);
  const categoryiesList = categories?.map((category) => ({
    label: category?.name,
    value: category?._id,
  }));

  const [takePicture, setTakePicture] = useState(false);
  const [takeVideo, setTakeVideo] = useState(false);
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  const [videoAnchorEl, setVideoAnchorEl] = useState(null);
  const [video, setVideo] = useState(
    productData?.video ?? formData?.video ?? ""
  );
  const [images, setImages] = useState(Array.from({ length: MAX_FILES }));
  const [removedIds, setRemovedIds] = useState([]);
  const [loaders, setLoaders] = useState(new Array(MAX_FILES).fill(false));

  const initialValues = {
    length: formData?.length ?? productData?.length ?? "",
    lengthUnit:
      Const.unitOptions.find(
        (ele) =>
          ele.value == formData?.lengthUnit?.value ||
          ele.value == productData?.lengthUnit
      ) ?? Const.unitOptions[0],
    width: formData?.width ?? productData?.width ?? "",
    widthUnit:
      Const.unitOptions.find(
        (ele) =>
          ele.value == formData?.widthUnit?.value ||
          ele.value == productData?.widthUnit
      ) ?? Const.unitOptions[0],
    major_color: Const.colors.find(
      (ele) =>
        ele.value == formData?.major_color?.value ||
        ele.value == productData?.major_color
    ),
    minor_color: Const.colors.find(
      (ele) =>
        ele.value == formData?.minor_color?.value ||
        ele.value == productData?.minor_color
    ),
    category: categoryiesList.find(
      (ele) =>
        ele.value == formData?.category?.value ||
        ele.value == productData?.category?._id
    ),
    thickness: Const.thicknessOptions.find(
      (ele) =>
        ele.value == formData?.thickness?.value ||
        ele.value == productData?.thickness
    ),
    surfaces: Const.surfaceOptions.find(
      (ele) =>
        ele.value == formData?.surfaces?.value ||
        ele.value == productData?.surfaces
    ),
    newbrand:
      Const.brandOptions.find(
        (ele) =>
          ele.label == formData?.newbrand?.label ||
          ele.value == formData?.newbrand ||
          ele.label == productData?.newbrand
      ) ??
      productData?.newbrand ??
      null,
    productName:
      Const.getBrandProducts(
        formData?.newbrand?.label || formData?.newbrand || productData?.newbrand
      )?.find(
        (ele) =>
          ele.value == formData?.productName?.value ||
          ele.value == formData?.productName ||
          ele.value == productData?.productName
      ) ??
      formData?.productName?.value ??
      formData?.productName ??
      productData?.productName ??
      null,
    name: formData?.name ?? productData?.name ?? "",
    description: formData?.description ?? productData?.description ?? "",
    finalPrice: formData?.finalPrice ?? productData?.finalPrice ?? null,
    price: formData?.price ?? productData?.price ?? null,
    stock: formData?.stock ?? productData?.inStock ?? null,
    sku: formData?.sku ?? productData?.sku ?? Const.generateRandomNumber(12),
    countryOfOrigin: countryList?.find(
      (ele) =>
        ele.value == formData?.countryOfOrigin?.value ||
        ele.value == productData?.countryOfOrigin
    ),
    includeFreeDelivery:
      formData?.includeFreeDelivery ??
      productData?.includeFreeDelivery ??
      false,
    freeDeliveryDistance:
      formData?.freeDeliveryDistance ?? productData?.freeDeliveryDistance ?? 30,
    deliveryDistance:
      formData?.deliveryDistance ?? productData?.deliveryDistance ?? 70,
    isPickupOnly: formData?.isPickupOnly ?? productData?.isPickupOnly ?? false,
    isMaterialOnly:
      formData?.isMaterialOnly ?? productData?.isMaterialOnly ?? false,
  };

  const { register, handleSubmit, setValue, watch, formState, control } =
    useForm({
      defaultValues: initialValues,
      resolver: yupResolver(productValidationSchema),
    });

  const { errors, isValid, isSubmitted, dirtyFields } = formState;

  const length = watch("length");
  const lengthUnit = watch("lengthUnit");
  const width = watch("width");
  const widthUnit = watch("widthUnit");
  const majorColor = watch("major_color");
  const minorColor = watch("minor_color");
  const category = watch("category");
  const thickness = watch("thickness");
  const surfaces = watch("surfaces");
  const newBrand = watch("newbrand");
  const countryOfOrigin = watch("countryOfOrigin");
  const productName = watch("productName");
  const name = watch("name");
  const description = watch("description");
  const sku = watch("sku");
  const stock = watch("stock");
  const finalPrice = watch("finalPrice");
  const price = watch("price");
  const includeFreeDelivery = watch("includeFreeDelivery");
  const freeDeliveryDistance = watch("freeDeliveryDistance", 30);
  const deliveryDistance = watch("deliveryDistance", 70);
  const isPickupOnly = watch("isPickupOnly");
  const isMaterialOnly = watch("isMaterialOnly");

  useEffect(() => {
    auth?.token && getCategories({ deleted: false }, auth?.token);
  }, [auth?.token]);

  useEffect(() => {
    return () => {
      if (location.state) {
        saveFormData({});
      }
    };
  }, []);

  useEffect(() => {
    if (productData?.productPic?.length > 0) {
      setImages(
        [
          ...productData?.productPic,
          ...Array.from({
            length: MAX_FILES - productData?.productPic?.length,
          }),
        ].map((file) => (file?.url ? { ...file, old: true } : undefined))
      );
    }
  }, [productData]);

  useEffect(() => {
    if (formData?.productPic) {
      const availablePics = formData?.productPic?.filter((file) => file);
      setImages([
        ...availablePics,
        ...Array.from({ length: MAX_FILES - availablePics?.length }),
      ]);
    } else if (!location.state) {
      setImages(Array.from({ length: MAX_FILES }));
    }
  }, [location.state, formData]);

  useEffect(() => {
    const withoutCountertop = category?.label?.split(" ")?.[0];
    const name = `
    ${length && width ? `<b>${length ?? ""}" X ${width ?? ""}"</b><br>` : ""}
    ${category?.label ? `<b>${category?.label} Slab</b><br>` : ""}
    <b>${
      (newBrand?.label || newBrand) === "None"
        ? ""
        : newBrand?.label
        ? newBrand?.label
        : newBrand ?? withoutCountertop ?? ""
    } ${thickness?.label ?? ""} ${surfaces?.label ?? ""}</b>
    `
      .trim()
      .replace(/<br>$/, "");

    const description = `
    ${
      productName?.label || productName
        ? `<b>Product Name:</b> ${productName?.label ?? productName}<br>`
        : ""
    }
    ${
      newBrand?.label || newBrand
        ? `<b>Brand:</b> ${newBrand?.label ?? newBrand ?? ""} ${
            newBrand?.label === "None" || newBrand === "None"
              ? ""
              : (newBrand?.label || newBrand).includes(
                  category?.label?.split(" ")?.[0]
                )
              ? ""
              : Const.brandRelatedCategories.find((cat) =>
                  cat.match((newBrand?.label ?? newBrand)?.toLowerCase())
                ) &&
                category?.label?.split(" ")?.[0] ==
                  (newBrand?.label ?? newBrand)
              ? ""
              : category?.label.split(" ")[0]
          }<br>`
        : ""
    }
    ${
      majorColor?.label && minorColor?.label
        ? `<b>Color:</b> ${majorColor?.label ?? ""} and ${
            minorColor?.label ?? ""
          }<br>`
        : ""
    }
    ${
      length && lengthUnit?.label
        ? `<b>Length:</b> ${length} ${lengthUnit?.label}<br>`
        : ""
    }
    ${
      width && widthUnit?.label
        ? `<b>Width:</b> ${width} ${widthUnit?.label}<br>`
        : ""
    }
    ${
      countryOfOrigin?.label
        ? `<b>Country of Origin:</b> ${countryOfOrigin?.label}<br>`
        : ""
    }
    ${sku ? `<b>SKU:</b> ${sku}<br>` : ""}
    ${isMaterialOnly ? `<b>Material Only!</b><br>` : ""}
    ${
      includeFreeDelivery
        ? `<b><span>Free Delivery:</span> ${freeDeliveryDistance} Miles</b><br>`
        : ""
    }
    ${
      !isPickupOnly && deliveryDistance
        ? `<b><span>Max Delivery:</span> ${deliveryDistance} Miles</b><br>`
        : ""
    }
    ${isPickupOnly ? `<b>Pickup Only!</b>` : ""}
    `
      .trim()
      .replace(/<br>$/, "");

    setValue("name", name.trim());
    setValue("description", description.trim());

    if (!productData)
      saveFormData({
        ...formData,
        length,
        lengthUnit,
        width,
        widthUnit,
        major_color: majorColor,
        minor_color: minorColor,
        category,
        countryOfOrigin,
        newbrand: newBrand,
        thickness,
        surfaces,
        productName,
        sku,
        stock,
        finalPrice,
        price,
        includeFreeDelivery,
        freeDeliveryDistance: freeDeliveryDistance ? freeDeliveryDistance : 30,
        deliveryDistance: deliveryDistance ? deliveryDistance : 70,
        isPickupOnly,
        isMaterialOnly,
      });
  }, [
    length,
    lengthUnit,
    width,
    widthUnit,
    majorColor?.label,
    minorColor?.label,
    category,
    countryOfOrigin,
    newBrand,
    thickness,
    surfaces,
    productName,
    sku,
    stock,
    finalPrice,
    price,
    includeFreeDelivery,
    freeDeliveryDistance,
    deliveryDistance,
    isPickupOnly,
    isMaterialOnly,
  ]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async (result) => {
          if (result.state === "denied") {
            const result = await post(
              `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            );
            const location = result?.location;
            setValue("lat", location?.lat);
            setValue("lng", location?.lng);
          } else {
            navigator.geolocation.getCurrentPosition((position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              setValue("lat", lat);
              setValue("lng", lng);
            });
          }
        });
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedImages = reorder(
      images,
      result.source.index,
      result.destination.index
    );
    setImages(reorderedImages.map((file, i) => ({ ...file, priority: i + 1 })));
  };

  const onUploadImage = async (filesArray) => {
    setImageAnchorEl(null);

    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const heifFiles = [];
    const normalFiles = [];

    const limitedFilesArray = filesArray.slice(0, MAX_FILES);
    limitedFilesArray.forEach((file) => {
      const fileType = file.name.split(".").at(-1).toLowerCase();
      if (fileType === "heif" || fileType === "heic") {
        heifFiles.push(file);
      } else {
        normalFiles.push(file);
      }
    });

    const uploadNormalFiles = async () => {
      const uploadedNormalFiles = [];
      if (normalFiles.length === 0) return [];

      const formData = new FormData();
      const emptySlots = images
        .map((image, index) => (image ? undefined : index))
        .filter((index) => index !== undefined);

      for (let i = 0; i < normalFiles.length; i++) {
        const file = normalFiles[i];
        const slotIndex = emptySlots[i];

        if (slotIndex === undefined) continue;

        try {
          setLoaders((prevLoaders) => {
            const updatedLoaders = [...prevLoaders];
            updatedLoaders[slotIndex] = true;
            return updatedLoaders;
          });

          const compressedFile = await imageCompression(file, options);
          formData.append("media", compressedFile);
        } catch (error) {
          errorResponse(error);
        }
      }

      try {
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY,
        });

        uploadedNormalFiles.push(...(result?.data || []));
      } catch (error) {
        errorResponse(error);
      } finally {
        setLoaders((prevLoaders) => {
          const updatedLoaders = [...prevLoaders];
          emptySlots.forEach((slotIndex) => {
            updatedLoaders[slotIndex] = false;
          });
          return updatedLoaders;
        });
      }

      return uploadedNormalFiles;
    };

    const convertHeifFiles = async () => {
      const uploadedHeifFiles = [];
      if (heifFiles.length === 0) return [];

      const formData = new FormData();
      const emptySlots = images
        .map((image, index) => (image ? undefined : index))
        .filter((index) => index !== undefined);

      for (let i = 0; i < heifFiles.length; i++) {
        const file = heifFiles[i];
        const slotIndex = emptySlots[i];

        if (slotIndex === undefined) continue;

        try {
          setLoaders((prevLoaders) => {
            const updatedLoaders = [...prevLoaders];
            updatedLoaders[slotIndex] = true;
            return updatedLoaders;
          });

          formData.append("media", file);
        } catch (error) {
          errorResponse(error);
        }
      }

      try {
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedHeifFiles.push(...(result?.data || []));
        return result?.data || [];
      } catch (error) {
        errorResponse(error);
      } finally {
        setLoaders((prevLoaders) => {
          const updatedLoaders = [...prevLoaders];
          emptySlots.forEach((slotIndex) => {
            updatedLoaders[slotIndex] = false;
          });
          return updatedLoaders;
        });
      }

      return uploadedHeifFiles;
    };

    const [uploadedNormalFiles, convertedHeifFiles] = await Promise.all([
      uploadNormalFiles(),
      convertHeifFiles(),
    ]);

    const uploadedFiles = [...uploadedNormalFiles, ...convertedHeifFiles];
    let newImages = [...images];

    const existingPriorities = newImages
      .filter((img) => img)
      .map((img) => img.priority);

    const nextPriority =
      existingPriorities.length > 0 ? Math.max(...existingPriorities) + 1 : 1;

    const prioritizedUploadedFiles = uploadedFiles.map((file, index) => ({
      ...file,
      priority: nextPriority + index,
    }));

    newImages = newImages
      .map((img) => img || prioritizedUploadedFiles.shift())
      .concat(prioritizedUploadedFiles);

    if (newImages.length > MAX_FILES) {
      newImages = newImages.slice(0, MAX_FILES);
    }

    saveFormData({ ...formData, productPic: newImages });
    setImages(newImages);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);

    if (droppedFiles.length > 0) {
      await onUploadImage(droppedFiles);
    }
  };

  const onDeleteImage = (id, index) => {
    if (id) setRemovedIds((ids) => [...ids, id]);
    const filteredImages = images.filter((_, i) => i !== index);
    const paddedImages = [
      ...filteredImages,
      ...Array.from({ length: MAX_FILES - filteredImages.length }),
    ];
    setImages(paddedImages);
    const productPic = formData?.productPic?.filter((_, idx) => idx !== index);
    saveFormData({ ...formData, productPic });
  };

  const onUploadVideo = async (event) => {
    const file = event.target.files[0];
    setImageAnchorEl(null);
    setVideoAnchorEl(null);

    try {
      const bodyFormData = new FormData();
      bodyFormData.append("media", file);
      const result = await post(`/users/compress`, bodyFormData, {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      });
      setVideo(result?.data);
      saveFormData({ ...formData, video: result?.data });
    } catch (error) {
      errorResponse(error);
    }
  };

  const clearForm = () => {
    setRemovedIds([]);
    setImages(Array.from({ length: 3 }));
    setVideo(null);
    setImageAnchorEl(null);
    setVideoAnchorEl(null);
  };

  const onSubmit = (formFields) => {
    if (isValid && !images.every((image) => image === undefined)) {
      if (productData) {
        const priorities = images.reduce((acc, item) => {
          if (item?.url) {
            acc[item?.url] = item;
          }
          return acc;
        }, {});

        const updateObj = {
          video,
          name: formFields?.name,
          description: formFields?.description,
          price: formFields?.price,
          finalPrice: formFields?.finalPrice,
          stock: formFields?.stock,
          stockChanged: dirtyFields.stock,
          category: formFields?.category?.value,
          major_color: formFields?.major_color?.value,
          minor_color: formFields?.minor_color?.value,
          length: formFields?.length,
          lengthUnit: formFields?.lengthUnit?.value,
          width: formFields?.width,
          widthUnit: formFields?.widthUnit?.value,
          surfaces: formFields?.surfaces?.value,
          thickness: formFields?.thickness?.value,
          newbrand: formFields?.newbrand?.label ?? formFields?.newbrand,
          productName:
            formFields?.productName?.value ?? formFields?.productName,
          countryOfOrigin: formFields?.countryOfOrigin?.value,
          includeFreeDelivery: formFields?.includeFreeDelivery,
          freeDeliveryDistance: formFields?.freeDeliveryDistance,
          deliveryDistance: formFields?.deliveryDistance,
          isPickupOnly: formFields?.isPickupOnly,
          isMaterialOnly: formFields?.isMaterialOnly,
          productPic: images.filter((image) => image?.url && !image?.old),
          priorities,
          _ids: removedIds,
        };

        !addProductLoading &&
          updateProduct(productData?._id, updateObj, auth?.token, clearForm);
      } else {
        const createdObj = {
          lat: formFields?.lat ?? 0,
          lng: formFields?.lng ?? 0,
          video,
          name: formFields?.name,
          description: formFields?.description,
          price: formFields?.price,
          finalPrice: formFields?.finalPrice,
          stock: formFields?.stock,
          owner: auth.user.userId,
          sku: formFields?.sku,
          category: formFields?.category?.value,
          major_color: formFields?.major_color?.value,
          minor_color: formFields?.minor_color?.value,
          length: formFields?.length,
          lengthUnit: formFields?.lengthUnit?.value,
          width: formFields?.width,
          widthUnit: formFields?.widthUnit?.value,
          surfaces: formFields?.surfaces?.value,
          thickness: formFields?.thickness?.value,
          newbrand: formFields?.newbrand?.label ?? formFields?.newbrand,
          productName:
            formFields?.productName?.value ?? formFields?.productName,
          countryOfOrigin: formFields?.countryOfOrigin?.value,
          includeFreeDelivery: formFields?.includeFreeDelivery,
          freeDeliveryDistance: formFields?.freeDeliveryDistance,
          deliveryDistance: formFields?.deliveryDistance,
          isPickupOnly: formFields?.isPickupOnly,
          isMaterialOnly: formFields?.isMaterialOnly,
          productPic: images
            .filter((image) => image?.url)
            .map((item, i) => ({ ...item, priority: i + 1 })),
        };

        !addProductLoading && addProduct(createdObj, auth?.token, clearForm);
      }

      saveFormData({});
    }
  };

  const onCancel = () => {
    clearForm();
    saveFormData({});
    props.history.push(`/dashboard/products`);
  };

  const renderImagePreview = useMemo(() => {
    const memoizedImages = images.map((image) => image);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <div
              className="box-container d-flex w-100 mt-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {memoizedImages
                ?.sort((a, b) => a?.priority - b?.priority)
                ?.map((image, i) => (
                  <Draggable key={i} draggableId={`image-${i}`} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="box-wrapper"
                      >
                        {loaders[i] ? (
                          <div className="item">
                            <div className="box">
                              <CircularProgress variant="indeterminate" />
                            </div>
                          </div>
                        ) : image?.url || image?.img ? ( // Will remove image.img
                          <div key={i} className="item">
                            <Close
                              onClick={() => onDeleteImage(image?._id, i)}
                              className="close"
                            />
                            <div className="box">
                              <img
                                src={image?.url ?? image?.img} // Will remove image.img
                                alt="Product Image"
                                width={300}
                                height={300}
                              />
                            </div>
                          </div>
                        ) : (
                          <div key={i} className="item">
                            <div
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(event) => handleDrop(event, i)}
                              onDragLeave={(e) => e.preventDefault()}
                              onClick={(event) =>
                                setImageAnchorEl(event.currentTarget)
                              }
                              className="box"
                            >
                              <Add />
                            </div>
                            <Popover
                              open={imageAnchorEl !== null}
                              anchorEl={imageAnchorEl}
                              onClose={() => setImageAnchorEl(null)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                            >
                              <div className="p-3">
                                <Typography as="h3">Upload images</Typography>
                                <div className="d-flex flex-column mt-2">
                                  <button
                                    onClick={() => {
                                      setTakePicture(true);
                                      setImageAnchorEl(null);
                                    }}
                                    className="mt-2 text-black"
                                  >
                                    Take a picture
                                  </button>
                                  <span className="text-center">Or</span>
                                  <input
                                    className="form-control"
                                    multiple
                                    type="file"
                                    accept="image/jpeg,image/jpg,image/png"
                                    onChange={(event) => {
                                      const filesArray = Array.from(
                                        event.target.files
                                      );
                                      onUploadImage(filesArray);
                                    }}
                                  />
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [images, imageAnchorEl, onDragEnd]);

  const renderVideoPreview = useMemo(() => {
    return (
      <div
        onClick={(event) => setVideoAnchorEl(event.currentTarget)}
        className="upload-video-wrapper"
      >
        {video && <Close onClick={() => setVideo(null)} className="close" />}
        <video
          style={{
            width: "100%",
            height: "100%",
            opacity: video ? 1 : 0,
          }}
          src={video ? video?.url : null}
          controls
          controlsList="nodownload"
        >
          Your browser does not support the video tag.
        </video>
        {!video && <Add className="position-absolute" />}
      </div>
    );
  }, [video, videoAnchorEl]);

  const commonProps = {
    register,
    errors,
  };

  const productLists = Const.getBrandProducts(newBrand?.label);

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Add a product</h2>
                </div>
                <div className="position-relative">
                  <div className="new-product">
                    <div className="form-container position-relative">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-100 d-flex flex-column"
                      >
                        <SelectField
                          name="category"
                          control={control}
                          placeholder="Select Category"
                          options={categoryiesList}
                          {...commonProps}
                        />
                        {category?.label && (
                          <Controller
                            control={control}
                            name="productName"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                value={value}
                                className="m-0 mb-3"
                                fullWidth
                                onChange={(_, newValue) => {
                                  if (newValue && newValue.inputValue) {
                                    onChange(newValue.inputValue);
                                    setValue(
                                      "productName",
                                      newValue.inputValue
                                    );
                                  } else {
                                    onChange(newValue);
                                    setValue("productName", newValue);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== "") {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      label: `Add "${params.inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                options={productLists ?? []}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option.label;
                                }}
                                renderOption={(option) => option.label}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Enter Product Name"
                                    variant="outlined"
                                    className="text-gray-500"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                        {category?.label && (
                          <Controller
                            control={control}
                            name="newbrand"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                value={value}
                                className="m-0 mb-3"
                                fullWidth
                                onChange={(_, newValue) => {
                                  if (newValue && newValue.inputValue) {
                                    onChange(newValue.inputValue);
                                    setValue("newbrand", newValue.inputValue);
                                  } else {
                                    onChange(newValue);
                                    setValue("newbrand", newValue);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== "") {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      label: `Add "${params.inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                options={Const.brandOptions}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option.label;
                                }}
                                renderOption={(option) => option.label}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Enter Brand Name"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                        <SelectField
                          name="major_color"
                          control={control}
                          placeholder="Select Major Color"
                          options={Const.colors}
                          {...commonProps}
                        />
                        <SelectField
                          name="minor_color"
                          control={control}
                          placeholder="Select Minor Color"
                          options={Const.colors}
                          {...commonProps}
                        />
                        <InputField
                          name="length"
                          label="Length"
                          {...commonProps}
                        >
                          <div style={{ width: "150px" }}>
                            <SelectField
                              name="lengthUnit"
                              control={control}
                              options={Const.unitOptions}
                              separate={false}
                              {...commonProps}
                            />
                          </div>
                        </InputField>
                        <InputField
                          name="width"
                          label="Width"
                          className="mb-3"
                          {...commonProps}
                        >
                          <div style={{ width: "150px" }}>
                            <SelectField
                              name="widthUnit"
                              control={control}
                              options={Const.unitOptions}
                              separate={false}
                              {...commonProps}
                            />
                          </div>
                        </InputField>
                        <SelectField
                          name="thickness"
                          control={control}
                          placeholder="Select Thickness"
                          options={Const.thicknessOptions}
                          {...commonProps}
                        />
                        <SelectField
                          name="surfaces"
                          control={control}
                          placeholder="Select Surface Finish"
                          options={Const.surfaceOptions}
                          {...commonProps}
                        />
                        <SelectField
                          name="countryOfOrigin"
                          control={control}
                          placeholder="Select Country"
                          options={countryList}
                          {...commonProps}
                        />
                        <div className="d-flex m-0 mb-4 align-items-center">
                          <Controller
                            control={control}
                            name="includeFreeDelivery"
                            render={({ field: { onChange, value } }) => (
                              <AntSwitch
                                onChange={(checked) => {
                                  onChange(checked);
                                  setValue("isPickupOnly", !checked);
                                }}
                                checked={value}
                              />
                            )}
                          />
                          <div className="ml-2 text-sm cursor-pointer whitespace-nowrap">
                            Include Free Delivery
                          </div>
                        </div>
                        {!isPickupOnly && includeFreeDelivery && (
                          <InputField
                            type="number"
                            name="freeDeliveryDistance"
                            label="Max Free delivery distance"
                            {...commonProps}
                          />
                        )}
                        {!isPickupOnly && (
                          <InputField
                            type="number"
                            name="deliveryDistance"
                            label="Max Delivery distance"
                            {...commonProps}
                          />
                        )}
                        <div className="d-flex m-0 mb-4 align-items-center">
                          <Controller
                            control={control}
                            name="isPickupOnly"
                            render={({ field: { onChange, value } }) => (
                              <AntSwitch
                                onChange={(checked) => {
                                  onChange(checked);
                                  setValue("includeFreeDelivery", !checked);
                                }}
                                checked={value}
                              />
                            )}
                          />
                          <div className="ml-2 text-sm cursor-pointer whitespace-nowrap">
                            Pickup Only
                          </div>
                        </div>
                        <div className="d-flex m-0 mb-4 align-items-center">
                          <Controller
                            control={control}
                            name="isMaterialOnly"
                            render={({ field: { onChange, value } }) => (
                              <AntSwitch
                                onChange={(checked) => onChange(checked)}
                                checked={value}
                              />
                            )}
                          />
                          <div className="ml-2 text-sm cursor-pointer whitespace-nowrap">
                            Material Only
                          </div>
                        </div>
                        <FormGroup className="mb-3 w-100 m-0">
                          <div
                            className="py-2 px-2 border rounded"
                            dangerouslySetInnerHTML={{
                              __html: name.includes("X")
                                ? name
                                : "Product Title",
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3 w-100 m-0">
                          <div
                            className="py-2 px-2 border rounded"
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </FormGroup>
                        <InputField
                          type="number"
                          name="price"
                          label="Price"
                          {...commonProps}
                        />
                        <InputField
                          type="number"
                          name="finalPrice"
                          label="Final Price"
                          {...commonProps}
                        />
                        <InputField
                          type="number"
                          name="stock"
                          label="Stock"
                          {...commonProps}
                        />
                        <InputField
                          disabled={productData != null}
                          name="sku"
                          label="Sku"
                          {...commonProps}
                        />
                        <FormGroup className="w-100 my-3 mx-0">
                          <div className="font-roboto">
                            Add Product Pictures 1 Full Front, 1 Right Profile
                            at 45 degrees, 1 Left Profile at 45 degress.
                          </div>
                          <div className="box-container d-flex w-100 mt-2">
                            {renderImagePreview}
                          </div>
                          {isSubmitted &&
                            images.every((image) => image === undefined) && (
                              <span className="text-danger text-left">
                                Please upload image
                              </span>
                            )}
                        </FormGroup>
                        <FormGroup className="w-full my-3 mx-0">
                          <div className="font-roboto">
                            Add Product Video Maximum 30s.
                          </div>
                          {renderVideoPreview}
                          <Popover
                            open={videoAnchorEl !== null}
                            anchorEl={videoAnchorEl}
                            onClose={() => setVideoAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "center",
                            }}
                          >
                            <div className="p-3">
                              <Typography as="h3">Upload video</Typography>
                              <div className="d-flex gap-2 flex-column mt-2">
                                <button
                                  onClick={() => {
                                    setTakeVideo(true);
                                    setVideoAnchorEl(null);
                                  }}
                                  className="rounded-md btn btn-secondary text-black"
                                >
                                  Take a video
                                </button>
                                <span className="text-center">Or</span>
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="video/*"
                                  onChange={onUploadVideo}
                                />
                              </div>
                            </div>
                          </Popover>
                          {isSubmitted && !video && (
                            <span className="text-danger">
                              Please upload video
                            </span>
                          )}
                        </FormGroup>
                        <div className="btn-group mb-3 d-flex">
                          <Button
                            className={`action py-2 px-5 d-flex text-nowrap border-none text-white justify-content-center align-items-center ${
                              productData ? "bg-success" : "bg-primary"
                            }`}
                            startIcon={
                              addProductLoading && (
                                <CircularProgress
                                  className="text-white"
                                  size={25}
                                />
                              )
                            }
                            type="submit"
                          >
                            {productData ? "Update" : "Submit Product Listing"}
                          </Button>
                          <Button
                            className="py-2 px-5 text-white bg-danger"
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                    {takePicture && (
                      <Dialog
                        fullWidth
                        maxWidth="sm"
                        open={true}
                        onClose={() => setTakePicture(false)}
                      >
                        <DialogTitle>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <h5 className="text-secondary">Take a picture</h5>
                            <IconButton
                              aria-label="close"
                              onClick={() => setTakePicture(false)}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </DialogTitle>
                        <DialogContent dividers>
                          <Camera
                            showCamera={true}
                            frameSize={{ width: "500px", height: "420px" }}
                            onFileUpload={(file) => onUploadImage([file])}
                            onClose={() => setTakePicture(false)}
                          />
                        </DialogContent>
                      </Dialog>
                    )}
                    {takeVideo && (
                      <Dialog
                        fullWidth
                        maxWidth="sm"
                        open={true}
                        onClose={() => setTakeVideo(false)}
                      >
                        <DialogTitle>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <h5>Take a picture</h5>
                            <Close
                              className="cursor-pointer text-dark"
                              onClick={() => setTakeVideo(false)}
                            />
                          </div>
                        </DialogTitle>
                        <DialogContent dividers>
                          <div
                            style={{ width: 500, height: 420, margin: "auto" }}
                          >
                            <ReactVideo
                              onRecordingComplete={(videoBlob) => {
                                const file = new File(
                                  [videoBlob],
                                  `video-${Date.now()}`,
                                  {
                                    type: videoBlob.type,
                                  }
                                );
                                setVideo(file);
                                setTakeVideo(false);
                              }}
                              countdownTime={0}
                              timeLimit={30000}
                            />
                          </div>
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    products: state.product.products,
    formData: state.product.formData,
    categories: state.category.categories,
    addProductLoading: state.product.addProductLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(productActions.getProducts()),
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    addProduct: (product, token) =>
      dispatch(productActions.addProduct(product, token)),
    updateProduct: (_id, product, token) =>
      dispatch(productActions.updateProduct(_id, product, token)),
    saveFormData: (formData) => dispatch(productActions.saveFormData(formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
